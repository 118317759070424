/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Ashutosh Kushawaha",
  title: "Hi all, I'm Ashutosh",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web based applications with JavaScript / Reactjs / Angular / Nodejs / .Net core / MySQL / SQL Server and some other cool libraries and frameworks."
  ),
  resumeLink: "https://ashutosh.laganbooking.com", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  // github: "https://github.com/saadpasta",
  linkedin: "https://www.linkedin.com/in/ashutosh-Kushawaha84/",
  gmail: "ashutosh.tech84@gmail.com",
  // gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/ashutosh.kuswaha",
  // medium: "https://medium.com/@saadpasta",
  // stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE AND LEARN",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces and reliable Backend solution for your web applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Azure / Financial charts"
    ),
    emoji(
      "⚡ Google Anlytics and Accessibility enabled web application"
    ),
    emoji(
      "⚡ ChartIQ, Highcharts.js, Admin dashboard, MySQL and SQL server databases, Asp.net Core, Asp.net MVC, C#, Jenkins pipeline, UI Automation, Unit test cases"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "U.P. Technical University",
      logo: require("./assets/images/apj_kalam_university.jpg"),
      subHeader: "Master of Computer Applications",
      duration: "June 2006 - April 2009",
      desc: "Major's in Programing and Database",
      descBullets: []
    },
    {
      schoolName: "NIELIT - DOAECC Socity",
      logo: require("./assets/images/NIELIT_logo.jpg"),
      subHeader: "'O' Level",
      duration: "2003 - 2004",
      desc: "Major's in C, HTML/CSS and Foxpro",
      descBullets: []
    },
    {
      schoolName: "VBS Purvanchal University",
      logo: require("./assets/images/vbs-university.png"),
      subHeader: "Bachelor of Science in Mathemetics",
      duration: "June 2001 - June 2004",
      desc: "Graduated in Mathemetics, Physics and Defence Studies ",
      descBullets: []
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Project and Team Management",
      progressPercentage: "70%"
    },
    {
      Stack: "Database",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Tech Manager",
      company: "S&P Global",
      companylogo: require("./assets/images/sandp.png"),
      date: "July 2020 – Present",
      desc: "Identifying and resolving tech blocker, Improve code quality and implement best practices, Stake holders & Client communication and deliveries. Identifying all the possible improvement and value added required in project",
      descBullets: [
        "Project: E*Trade – Morgan Stanly Charts & Accessibility",
        "Tech Spec : ASP.Net MVC, C#, ReactJs , Node Js, Express Js, HTML, CSS, Chart.js, CharIQ"
      ]
    },
    {
      role: "Tech Lead",
      company: "Fiserv",
      companylogo: require("./assets/images/fiserv-logo.png"),
      date: "July 2017 – July 2020",
      desc: "As a Lead responsible for providing estimations and tech desing and helping out team when there is a need. Also doing a heavy lifting task and complex challenges.",
      descBullets: [
        "Project: Account Connect - Mortgage and Auto loan application",
        "Tech Spec : ASP.Net MVC, C#, ReactJs , Chart.js, SQL Server 2012"
      ]
    },
    {
      role: "Team Lead",
      company: "CG Infinity (Cyber Group)",
      companylogo: require("./assets/images/cybergroupinc_logo.jpeg"),
      date: "Aug 2013 – July 2017",
      desc: "Worked on Just Enery CRM onsite(Houston, TX, USA) for around 3 years and developed & maintain their sister companies(Tara energy, Amigo energy) web application.",
      descBullets: [
        "Project: Just Energy CRM",
        "Tech Spec : Angula.js, ASP.net MVC and SQL server. Also created a mobile app using Intel XE tool using cordova framework"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME BIG & ENTERPRISE LEVEL COMPLEX PROJECTS",
  projects: [
    {
      image: require("./assets/images/etrae_log.jpeg"),
      projectName: "E*Trade Charts",
      projectDesc:
        "Capital market based web application showing Stock, Mutual Funds and ETF details and charts",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://etrade.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/justenergy.jpg"),
      projectName: "Just Energy",
      projectDesc:
        "Largest energy ditribution company in Texas. Implemented a CRM to mainage their Product and Services and customer relationship. ",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://justenergy.com/"
        }
      ]
    },
    {
      image: require("./assets/images/lb_logo.png"),
      projectName: "Lagan Booking",
      projectDesc:
        "End to End full project of managing a multi-tenant application where an end user can booking their event vendor's and track their bookings and reviews. A complete wedding based event management system.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://laganbooking.com/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Multi-tenant Project Completion",
      subtitle:
        "Acheived multi-tenant project completion certificate by client.",
      image: require("./assets/images/Project_Certificate.png"),
      imageAlt: "Architect - Multitenant Application",
      footerLink: [
        {
          name: "Certification",
          url: "#"
        },
        {
          name: "Award Letter",
          url: "#"
        }
      ]
    },
    {
      title: "Front End - React, Redux",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/KTIT_Certificate.png"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "./assets/images/KTIT_Certificate.png"
        }
      ]
    },

    {
      title: "Azure Cloud Fundamental",
      subtitle: "Completed Azure fundamental course from Microsoft",
      image: require("./assets/images/azure_fundamental.png"),
      imageAlt: "Azure Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "#"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blogs.ashutoshtech.com/blogs/designing-a-multitenant-school-management-system-database-key-considerations-and-best-practices",
      title: "Multitenant School Management System Database Design",
      description:
        "Designing a Multitenant School Management System Database: Key Considerations and Best Practices"
    },
    {
      url: "https://blogs.ashutoshtech.com/blogs/react-vs-angular-which-is-better",
      title: "React vs Angular: Which is Better?",
      description:
        "React vs Angular: Which is Better? Their Pros and Cons"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: ["Coming soon"],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-931-901-2098",
  email_address: "ashutosh.tech84@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "AshutoshTech84", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
